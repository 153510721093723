[
    {
        "catalan": "de",
        "english": "than",
        "usageinwikipedia": "626390"
    },
    {
        "catalan": "la",
        "english": "the",
        "usageinwikipedia": "421377"
    },
    {
        "catalan": "i",
        "english": "and",
        "usageinwikipedia": "326966"
    },
    {
        "catalan": "el",
        "english": "it",
        "usageinwikipedia": "315887"
    },
    {
        "catalan": "a",
        "english": "in",
        "usageinwikipedia": "291367"
    },
    {
        "catalan": "l'",
        "english": "the",
        "usageinwikipedia": 198913
    },
    {
        "catalan": "en",
        "english": "in",
        "usageinwikipedia": "179132"
    },
    {
        "catalan": "que",
        "english": "what",
        "usageinwikipedia": "172717"
    },
    {
        "catalan": "d'",
        "english": "ness",
        "usageinwikipedia": 164187
    },
    {
        "catalan": "va",
        "english": "goes",
        "usageinwikipedia": "163997"
    },
    {
        "catalan": "del",
        "english": "from",
        "usageinwikipedia": "145958"
    },
    {
        "catalan": "per",
        "english": "for",
        "usageinwikipedia": "123030"
    },
    {
        "catalan": "les",
        "english": "them",
        "usageinwikipedia": "119696"
    },
    {
        "catalan": "els",
        "english": "the",
        "usageinwikipedia": "111406"
    },
    {
        "catalan": "un",
        "english": "a",
        "usageinwikipedia": "106875"
    },
    {
        "catalan": "amb",
        "english": "with",
        "usageinwikipedia": "96761"
    },
    {
        "catalan": "es",
        "english": "is",
        "usageinwikipedia": "89761"
    },
    {
        "catalan": "al",
        "english": "at the",
        "usageinwikipedia": "89504"
    },
    {
        "catalan": "una",
        "english": "a",
        "usageinwikipedia": "89218"
    },
    {
        "catalan": "és",
        "english": "is",
        "usageinwikipedia": "62136"
    },
    {
        "catalan": "com",
        "english": "how",
        "usageinwikipedia": "60231"
    },
    {
        "catalan": "dels",
        "english": "dels",
        "usageinwikipedia": "56790"
    },
    {
        "catalan": "més",
        "english": "month",
        "usageinwikipedia": "43856"
    },
    {
        "catalan": "ser",
        "english": "to be",
        "usageinwikipedia": "39485"
    },
    {
        "catalan": "seu",
        "english": "his",
        "usageinwikipedia": "38700"
    },
    {
        "catalan": "van",
        "english": "are going",
        "usageinwikipedia": "36615"
    },
    {
        "catalan": "o",
        "english": "or",
        "usageinwikipedia": "36068"
    },
    {
        "catalan": "no",
        "english": "no",
        "usageinwikipedia": "34956"
    },
    {
        "catalan": "s'",
        "english": "s'",
        "usageinwikipedia": 34669
    },
    {
        "catalan": "seva",
        "english": "their",
        "usageinwikipedia": "31020"
    },
    {
        "catalan": "fou",
        "english": "fou",
        "usageinwikipedia": "29316"
    },
    {
        "catalan": "entre",
        "english": "between",
        "usageinwikipedia": "25240"
    },
    {
        "catalan": "també",
        "english": "too",
        "usageinwikipedia": "23744"
    },
    {
        "catalan": "però",
        "english": "But",
        "usageinwikipedia": "23499"
    },
    {
        "catalan": "als",
        "english": "to the",
        "usageinwikipedia": "21966"
    },
    {
        "catalan": "després",
        "english": "after",
        "usageinwikipedia": "21704"
    },
    {
        "catalan": "ha",
        "english": "ha",
        "usageinwikipedia": "21411"
    },
    {
        "catalan": "aquest",
        "english": "this",
        "usageinwikipedia": "21308"
    },
    {
        "catalan": "fins",
        "english": "to",
        "usageinwikipedia": "19929"
    },
    {
        "catalan": "any",
        "english": "year",
        "usageinwikipedia": "19162"
    },
    {
        "catalan": "són",
        "english": "they are",
        "usageinwikipedia": "18945"
    },
    {
        "catalan": "hi",
        "english": "hi",
        "usageinwikipedia": "18370"
    },
    {
        "catalan": "pel",
        "english": "skin",
        "usageinwikipedia": "17652"
    },
    {
        "catalan": "aquesta",
        "english": "this",
        "usageinwikipedia": "17553"
    },
    {
        "catalan": "durant",
        "english": "throughout",
        "usageinwikipedia": "16893"
    },
    {
        "catalan": "on",
        "english": "where",
        "usageinwikipedia": "16704"
    },
    {
        "catalan": "era",
        "english": "was",
        "usageinwikipedia": "15968"
    },
    {
        "catalan": "part",
        "english": "section",
        "usageinwikipedia": "15788"
    },
    {
        "catalan": "altres",
        "english": "others",
        "usageinwikipedia": "15562"
    },
    {
        "catalan": "anys",
        "english": "years",
        "usageinwikipedia": "15551"
    },
    {
        "catalan": "gran",
        "english": "great",
        "usageinwikipedia": "15410"
    },
    {
        "catalan": "ciutat",
        "english": "city",
        "usageinwikipedia": "15322"
    },
    {
        "catalan": "cap",
        "english": "none",
        "usageinwikipedia": "15282"
    },
    {
        "catalan": "des",
        "english": "since",
        "usageinwikipedia": "14490"
    },
    {
        "catalan": "seus",
        "english": "their",
        "usageinwikipedia": "14132"
    },
    {
        "catalan": "tot",
        "english": "all",
        "usageinwikipedia": "13669"
    },
    {
        "catalan": "estat",
        "english": "state",
        "usageinwikipedia": "12755"
    },
    {
        "catalan": "qual",
        "english": "which",
        "usageinwikipedia": "12513"
    },
    {
        "catalan": "segle",
        "english": "century",
        "usageinwikipedia": "12215"
    },
    {
        "catalan": "sobre",
        "english": "envelope",
        "usageinwikipedia": "11931"
    },
    {
        "catalan": "quan",
        "english": "when",
        "usageinwikipedia": "11923"
    },
    {
        "catalan": "guerra",
        "english": "war",
        "usageinwikipedia": "11853"
    },
    {
        "catalan": "ja",
        "english": "now",
        "usageinwikipedia": "11482"
    },
    {
        "catalan": "havia",
        "english": "had",
        "usageinwikipedia": "11361"
    },
    {
        "catalan": "molt",
        "english": "a lot",
        "usageinwikipedia": "11189"
    },
    {
        "catalan": "rei",
        "english": "king",
        "usageinwikipedia": "11069"
    },
    {
        "catalan": "dos",
        "english": "two",
        "usageinwikipedia": "10857"
    },
    {
        "catalan": "primer",
        "english": "first",
        "usageinwikipedia": "10565"
    },
    {
        "catalan": "nom",
        "english": "first name",
        "usageinwikipedia": "10447"
    },
    {
        "catalan": "fer",
        "english": "do",
        "usageinwikipedia": "9455"
    },
    {
        "catalan": "se",
        "english": "sin",
        "usageinwikipedia": "9185"
    },
    {
        "catalan": "així",
        "english": "like this",
        "usageinwikipedia": "9064"
    },
    {
        "catalan": "li",
        "english": "her",
        "usageinwikipedia": "9041"
    },
    {
        "catalan": "sant",
        "english": "holy",
        "usageinwikipedia": "8724"
    },
    {
        "catalan": "encara",
        "english": "yet",
        "usageinwikipedia": "8681"
    },
    {
        "catalan": "pels",
        "english": "for",
        "usageinwikipedia": "8418"
    },
    {
        "catalan": "primera",
        "english": "first",
        "usageinwikipedia": "8333"
    },
    {
        "catalan": "seves",
        "english": "their",
        "usageinwikipedia": "8199"
    },
    {
        "catalan": "contra",
        "english": "versus",
        "usageinwikipedia": "8146"
    },
    {
        "catalan": "té",
        "english": "has",
        "usageinwikipedia": "8074"
    },
    {
        "catalan": "partit",
        "english": "match",
        "usageinwikipedia": "8052"
    },
    {
        "catalan": "està",
        "english": "it's",
        "usageinwikipedia": "7971"
    },
    {
        "catalan": "mateix",
        "english": "same",
        "usageinwikipedia": "7852"
    },
    {
        "catalan": "forma",
        "english": "form",
        "usageinwikipedia": "7724"
    },
    {
        "catalan": "aC",
        "english": "AC",
        "usageinwikipedia": "7655"
    },
    {
        "catalan": "pot",
        "english": "can",
        "usageinwikipedia": "7386"
    },
    {
        "catalan": "nord",
        "english": "north",
        "usageinwikipedia": "7376"
    },
    {
        "catalan": "tres",
        "english": "three",
        "usageinwikipedia": "7260"
    },
    {
        "catalan": "temps",
        "english": "time",
        "usageinwikipedia": "7148"
    },
    {
        "catalan": "II",
        "english": "II",
        "usageinwikipedia": "7135"
    },
    {
        "catalan": "fill",
        "english": "son",
        "usageinwikipedia": "7005"
    },
    {
        "catalan": "han",
        "english": "han",
        "usageinwikipedia": "6917"
    },
    {
        "catalan": "només",
        "english": "not more",
        "usageinwikipedia": "6880"
    },
    {
        "catalan": "dues",
        "english": "two",
        "usageinwikipedia": "6865"
    },
    {
        "catalan": "si",
        "english": "Yes",
        "usageinwikipedia": "6865"
    },
    {
        "catalan": "sota",
        "english": "under",
        "usageinwikipedia": "6585"
    },
    {
        "catalan": "general",
        "english": "general",
        "usageinwikipedia": "6536"
    },
    {
        "catalan": "lloc",
        "english": "spot",
        "usageinwikipedia": "6359"
    },
    {
        "catalan": "això",
        "english": "this",
        "usageinwikipedia": "6335"
    },
    {
        "catalan": "Barcelona",
        "english": "Barcelona",
        "usageinwikipedia": "6273"
    },
    {
        "catalan": "alguns",
        "english": "some",
        "usageinwikipedia": "6157"
    },
    {
        "catalan": "govern",
        "english": "government",
        "usageinwikipedia": "6142"
    },
    {
        "catalan": "poder",
        "english": "may",
        "usageinwikipedia": "6133"
    },
    {
        "catalan": "uns",
        "english": "some",
        "usageinwikipedia": "6072"
    },
    {
        "catalan": "aquests",
        "english": "these",
        "usageinwikipedia": "6041"
    },
    {
        "catalan": "mort",
        "english": "death",
        "usageinwikipedia": "5946"
    },
    {
        "catalan": "nou",
        "english": "new",
        "usageinwikipedia": "5776"
    },
    {
        "catalan": "sud",
        "english": "south",
        "usageinwikipedia": "5769"
    },
    {
        "catalan": "tots",
        "english": "each",
        "usageinwikipedia": "5659"
    },
    {
        "catalan": "fet",
        "english": "fact",
        "usageinwikipedia": "5591"
    },
    {
        "catalan": "sense",
        "english": "without",
        "usageinwikipedia": "5543"
    },
    {
        "catalan": "França",
        "english": "France",
        "usageinwikipedia": "5471"
    },
    {
        "catalan": "grup",
        "english": "group",
        "usageinwikipedia": "5435"
    },
    {
        "catalan": "tant",
        "english": "so much",
        "usageinwikipedia": "5416"
    },
    {
        "catalan": "terme",
        "english": "term",
        "usageinwikipedia": "5352"
    },
    {
        "catalan": "fa",
        "english": "does",
        "usageinwikipedia": "5316"
    },
    {
        "catalan": "tenir",
        "english": "to own",
        "usageinwikipedia": "5307"
    },
    {
        "catalan": "segons",
        "english": "seconds",
        "usageinwikipedia": "5288"
    },
    {
        "catalan": "món",
        "english": "world",
        "usageinwikipedia": "5287"
    },
    {
        "catalan": "regne",
        "english": "kingdom",
        "usageinwikipedia": "5261"
    },
    {
        "catalan": "exèrcit",
        "english": "army",
        "usageinwikipedia": "5193"
    },
    {
        "catalan": "segona",
        "english": "second",
        "usageinwikipedia": "5174"
    },
    {
        "catalan": "abans",
        "english": "before",
        "usageinwikipedia": "5076"
    },
    {
        "catalan": "mentre",
        "english": "while",
        "usageinwikipedia": "5058"
    },
    {
        "catalan": "quals",
        "english": "which",
        "usageinwikipedia": "5006"
    },
    {
        "catalan": "aquestes",
        "english": "these",
        "usageinwikipedia": "4992"
    },
    {
        "catalan": "família",
        "english": "family",
        "usageinwikipedia": "4991"
    },
    {
        "catalan": "Catalunya",
        "english": "Catalonia",
        "usageinwikipedia": "4945"
    },
    {
        "catalan": "país",
        "english": "country",
        "usageinwikipedia": "4943"
    },
    {
        "catalan": "eren",
        "english": "they were",
        "usageinwikipedia": "4900"
    },
    {
        "catalan": "poden",
        "english": "they can",
        "usageinwikipedia": "4859"
    },
    {
        "catalan": "diferents",
        "english": "different",
        "usageinwikipedia": "4813"
    },
    {
        "catalan": "nova",
        "english": "new",
        "usageinwikipedia": "4800"
    },
    {
        "catalan": "molts",
        "english": "lots of",
        "usageinwikipedia": "4755"
    },
    {
        "catalan": "església",
        "english": "church",
        "usageinwikipedia": "4711"
    },
    {
        "catalan": "major",
        "english": "higher",
        "usageinwikipedia": "4700"
    },
    {
        "catalan": "club",
        "english": "club",
        "usageinwikipedia": "4687"
    },
    {
        "catalan": "manera",
        "english": "way",
        "usageinwikipedia": "4686"
    },
    {
        "catalan": "estats",
        "english": "states",
        "usageinwikipedia": "4672"
    },
    {
        "catalan": "seua",
        "english": "It's",
        "usageinwikipedia": "4669"
    },
    {
        "catalan": "diversos",
        "english": "various",
        "usageinwikipedia": "4608"
    },
    {
        "catalan": "grans",
        "english": "big",
        "usageinwikipedia": "4524"
    },
    {
        "catalan": "què",
        "english": "what",
        "usageinwikipedia": "4503"
    },
    {
        "catalan": "arribar",
        "english": "to arrive",
        "usageinwikipedia": "4487"
    },
    {
        "catalan": "troba",
        "english": "find",
        "usageinwikipedia": "4487"
    },
    {
        "catalan": "població",
        "english": "population",
        "usageinwikipedia": "4477"
    },
    {
        "catalan": "sistema",
        "english": "system",
        "usageinwikipedia": "4477"
    },
    {
        "catalan": "poble",
        "english": "village",
        "usageinwikipedia": "4441"
    },
    {
        "catalan": "foren",
        "english": "they were",
        "usageinwikipedia": "4404"
    },
    {
        "catalan": "m",
        "english": "m",
        "usageinwikipedia": "4351"
    },
    {
        "catalan": "època",
        "english": "age",
        "usageinwikipedia": "4310"
    },
    {
        "catalan": "haver",
        "english": "to have",
        "usageinwikipedia": "4309"
    },
    {
        "catalan": "cada",
        "english": "each",
        "usageinwikipedia": "4301"
    },
    {
        "catalan": "eleccions",
        "english": "Elections",
        "usageinwikipedia": "4251"
    },
    {
        "catalan": "diverses",
        "english": "various",
        "usageinwikipedia": "4223"
    },
    {
        "catalan": "obra",
        "english": "work",
        "usageinwikipedia": "4221"
    },
    {
        "catalan": "partir",
        "english": "part",
        "usageinwikipedia": "4215"
    },
    {
        "catalan": "tipus",
        "english": "type",
        "usageinwikipedia": "4206"
    },
    {
        "catalan": "riu",
        "english": "River",
        "usageinwikipedia": "4200"
    },
    {
        "catalan": "vida",
        "english": "life",
        "usageinwikipedia": "4154"
    },
    {
        "catalan": "dia",
        "english": "day",
        "usageinwikipedia": "4101"
    },
    {
        "catalan": "quatre",
        "english": "four",
        "usageinwikipedia": "4092"
    },
    {
        "catalan": "poc",
        "english": "little",
        "usageinwikipedia": "4083"
    },
    {
        "catalan": "regió",
        "english": "region",
        "usageinwikipedia": "4075"
    },
    {
        "catalan": "exemple",
        "english": "example",
        "usageinwikipedia": "4056"
    },
    {
        "catalan": "batalla",
        "english": "battle",
        "usageinwikipedia": "4040"
    },
    {
        "catalan": "altre",
        "english": "other",
        "usageinwikipedia": "4015"
    },
    {
        "catalan": "Espanya",
        "english": "Spain",
        "usageinwikipedia": "4008"
    },
    {
        "catalan": "Joan",
        "english": "Joan",
        "usageinwikipedia": "4005"
    },
    {
        "catalan": "actualment",
        "english": "presently",
        "usageinwikipedia": "3992"
    },
    {
        "catalan": "tenen",
        "english": "they have",
        "usageinwikipedia": "3988"
    },
    {
        "catalan": "dins",
        "english": "inside",
        "usageinwikipedia": "3979"
    },
    {
        "catalan": "llavors",
        "english": "then",
        "usageinwikipedia": "3964"
    },
    {
        "catalan": "centre",
        "english": "core",
        "usageinwikipedia": "3943"
    },
    {
        "catalan": "algunes",
        "english": "few",
        "usageinwikipedia": "3925"
    },
    {
        "catalan": "important",
        "english": "important",
        "usageinwikipedia": "3914"
    },
    {
        "catalan": "altra",
        "english": "other",
        "usageinwikipedia": "3879"
    },
    {
        "catalan": "terra",
        "english": "soil",
        "usageinwikipedia": "3835"
    },
    {
        "catalan": "antic",
        "english": "past",
        "usageinwikipedia": "3817"
    },
    {
        "catalan": "tenia",
        "english": "it had",
        "usageinwikipedia": "3774"
    },
    {
        "catalan": "zona",
        "english": "zone",
        "usageinwikipedia": "3762"
    },
    {
        "catalan": "obres",
        "english": "works",
        "usageinwikipedia": "3751"
    },
    {
        "catalan": "estava",
        "english": "he was",
        "usageinwikipedia": "3747"
    },
    {
        "catalan": "pare",
        "english": "dad",
        "usageinwikipedia": "3742"
    },
    {
        "catalan": "III",
        "english": "III",
        "usageinwikipedia": "3725"
    },
    {
        "catalan": "qui",
        "english": "who",
        "usageinwikipedia": "3680"
    },
    {
        "catalan": "ara",
        "english": "now",
        "usageinwikipedia": "3676"
    },
    {
        "catalan": "havien",
        "english": "they had",
        "usageinwikipedia": "3660"
    },
    {
        "catalan": "començar",
        "english": "to start",
        "usageinwikipedia": "3640"
    },
    {
        "catalan": "història",
        "english": "history",
        "usageinwikipedia": "3639"
    },
    {
        "catalan": "morir",
        "english": "to die",
        "usageinwikipedia": "3614"
    },
    {
        "catalan": "nacional",
        "english": "national",
        "usageinwikipedia": "3599"
    },
    {
        "catalan": "final",
        "english": "end",
        "usageinwikipedia": "3591"
    },
    {
        "catalan": "música",
        "english": "music",
        "usageinwikipedia": "3564"
    },
    {
        "catalan": "majoria",
        "english": "majority",
        "usageinwikipedia": "3562"
    },
    {
        "catalan": "Europa",
        "english": "Europe",
        "usageinwikipedia": "3546"
    },
    {
        "catalan": "posteriorment",
        "english": "subsequently",
        "usageinwikipedia": "3542"
    },
    {
        "catalan": "alemanya",
        "english": "Germany",
        "usageinwikipedia": "3540"
    },
    {
        "catalan": "principal",
        "english": "principal",
        "usageinwikipedia": "3509"
    },
    {
        "catalan": "finalment",
        "english": "finally",
        "usageinwikipedia": "3496"
    },
    {
        "catalan": "passar",
        "english": "to pass",
        "usageinwikipedia": "3485"
    },
    {
        "catalan": "punt",
        "english": "point",
        "usageinwikipedia": "3448"
    },
    {
        "catalan": "territori",
        "english": "territory",
        "usageinwikipedia": "3405"
    },
    {
        "catalan": "illa",
        "english": "island",
        "usageinwikipedia": "3397"
    },
    {
        "catalan": "unió",
        "english": "union",
        "usageinwikipedia": "3393"
    },
    {
        "catalan": "imperi",
        "english": "empire",
        "usageinwikipedia": "3379"
    },
    {
        "catalan": "donar",
        "english": "to give",
        "usageinwikipedia": "3361"
    },
    {
        "catalan": "mundial",
        "english": "global",
        "usageinwikipedia": "3361"
    },
    {
        "catalan": "república",
        "english": "republic",
        "usageinwikipedia": "3357"
    },
    {
        "catalan": "període",
        "english": "period",
        "usageinwikipedia": "3339"
    },
    {
        "catalan": "línia",
        "english": "line",
        "usageinwikipedia": "3328"
    },
    {
        "catalan": "tard",
        "english": "late",
        "usageinwikipedia": "3322"
    },
    {
        "catalan": "temporada",
        "english": "season",
        "usageinwikipedia": "3322"
    },
    {
        "catalan": "totes",
        "english": "all",
        "usageinwikipedia": "3306"
    },
    {
        "catalan": "sent",
        "english": "feel",
        "usageinwikipedia": "3281"
    },
    {
        "catalan": "ell",
        "english": "he",
        "usageinwikipedia": "3279"
    },
    {
        "catalan": "president",
        "english": "president",
        "usageinwikipedia": "3268"
    },
    {
        "catalan": "Maria",
        "english": "Marria",
        "usageinwikipedia": "3248"
    },
    {
        "catalan": "perquè",
        "english": "because",
        "usageinwikipedia": "3244"
    },
    {
        "catalan": "emperador",
        "english": "emperor",
        "usageinwikipedia": "3231"
    },
    {
        "catalan": "divisió",
        "english": "partition",
        "usageinwikipedia": "3228"
    },
    {
        "catalan": "política",
        "english": "politics",
        "usageinwikipedia": "3228"
    },
    {
        "catalan": "equip",
        "english": "gear",
        "usageinwikipedia": "3203"
    },
    {
        "catalan": "mar",
        "english": "sea",
        "usageinwikipedia": "3198"
    },
    {
        "catalan": "castell",
        "english": "castle",
        "usageinwikipedia": "3191"
    },
    {
        "catalan": "universitat",
        "english": "university",
        "usageinwikipedia": "3186"
    },
    {
        "catalan": "nomenat",
        "english": "nominated",
        "usageinwikipedia": "3169"
    },
    {
        "catalan": "capital",
        "english": "capital",
        "usageinwikipedia": "3154"
    },
    {
        "catalan": "dir",
        "english": "to say",
        "usageinwikipedia": "3150"
    },
    {
        "catalan": "segon",
        "english": "second",
        "usageinwikipedia": "3144"
    },
    {
        "catalan": "cosa",
        "english": "thing",
        "usageinwikipedia": "3129"
    },
    {
        "catalan": "aconseguir",
        "english": "achieve",
        "usageinwikipedia": "3120"
    },
    {
        "catalan": "the",
        "english": "The",
        "usageinwikipedia": "3116"
    },
    {
        "catalan": "Carles",
        "english": "Carles",
        "usageinwikipedia": "3101"
    },
    {
        "catalan": "actual",
        "english": "current",
        "usageinwikipedia": "3098"
    },
    {
        "catalan": "cas",
        "english": "case",
        "usageinwikipedia": "3094"
    },
    {
        "catalan": "banda",
        "english": "band",
        "usageinwikipedia": "3079"
    },
    {
        "catalan": "futbol",
        "english": "soccer",
        "usageinwikipedia": "3071"
    },
    {
        "catalan": "moment",
        "english": "moment",
        "usageinwikipedia": "3037"
    },
    {
        "catalan": "Roma",
        "english": "Rome",
        "usageinwikipedia": "3034"
    },
    {
        "catalan": "santa",
        "english": "anticipation",
        "usageinwikipedia": "3031"
    },
    {
        "catalan": "província",
        "english": "province",
        "usageinwikipedia": "3027"
    },
    {
        "catalan": "origen",
        "english": "origin",
        "usageinwikipedia": "3026"
    },
    {
        "catalan": "aigua",
        "english": "water",
        "usageinwikipedia": "3011"
    },
    {
        "catalan": "vegada",
        "english": "they",
        "usageinwikipedia": "2979"
    },
    {
        "catalan": "anomenat",
        "english": "called",
        "usageinwikipedia": "2977"
    },
    {
        "catalan": "rebre",
        "english": "receive",
        "usageinwikipedia": "2974"
    },
    {
        "catalan": "jocs",
        "english": "Games",
        "usageinwikipedia": "2969"
    },
    {
        "catalan": "primers",
        "english": "former",
        "usageinwikipedia": "2965"
    },
    {
        "catalan": "casa",
        "english": "household",
        "usageinwikipedia": "2964"
    },
    {
        "catalan": "km",
        "english": "km",
        "usageinwikipedia": "2955"
    },
    {
        "catalan": "bé",
        "english": "well",
        "usageinwikipedia": "2954"
    },
    {
        "catalan": "units",
        "english": "United",
        "usageinwikipedia": "2951"
    },
    {
        "catalan": "països",
        "english": "countries",
        "usageinwikipedia": "2947"
    },
    {
        "catalan": "causa",
        "english": "cause",
        "usageinwikipedia": "2946"
    },
    {
        "catalan": "germà",
        "english": "brother",
        "usageinwikipedia": "2945"
    },
    {
        "catalan": "tota",
        "english": "whole",
        "usageinwikipedia": "2943"
    },
    {
        "catalan": "tornar",
        "english": "come back",
        "usageinwikipedia": "2939"
    },
    {
        "catalan": "conegut",
        "english": "acquainted",
        "usageinwikipedia": "2911"
    },
    {
        "catalan": "situada",
        "english": "placed",
        "usageinwikipedia": "2907"
    },
    {
        "catalan": "unes",
        "english": "some",
        "usageinwikipedia": "2889"
    },
    {
        "catalan": "estan",
        "english": "they are",
        "usageinwikipedia": "2888"
    },
    {
        "catalan": "juliol",
        "english": "July",
        "usageinwikipedia": "2882"
    },
    {
        "catalan": "metres",
        "english": "meters",
        "usageinwikipedia": "2881"
    },
    {
        "catalan": "francès",
        "english": "french",
        "usageinwikipedia": "2876"
    },
    {
        "catalan": "Pere",
        "english": "Pere",
        "usageinwikipedia": "2872"
    },
    {
        "catalan": "València",
        "english": "Valencia",
        "usageinwikipedia": "2862"
    },
    {
        "catalan": "sol",
        "english": "ground",
        "usageinwikipedia": "2859"
    },
    {
        "catalan": "tal",
        "english": "wise",
        "usageinwikipedia": "2832"
    },
    {
        "catalan": "camp",
        "english": "rural area",
        "usageinwikipedia": "2829"
    },
    {
        "catalan": "formar",
        "english": "train",
        "usageinwikipedia": "2828"
    },
    {
        "catalan": "obtenir",
        "english": "get",
        "usageinwikipedia": "2822"
    },
    {
        "catalan": "creu",
        "english": "cross",
        "usageinwikipedia": "2818"
    },
    {
        "catalan": "vegades",
        "english": "times",
        "usageinwikipedia": "2809"
    },
    {
        "catalan": "nombre",
        "english": "number",
        "usageinwikipedia": "2801"
    },
    {
        "catalan": "força",
        "english": "power",
        "usageinwikipedia": "2793"
    },
    {
        "catalan": "ho",
        "english": "ho",
        "usageinwikipedia": "2779"
    },
    {
        "catalan": "dies",
        "english": "days",
        "usageinwikipedia": "2772"
    },
    {
        "catalan": "llengua",
        "english": "tongue",
        "usageinwikipedia": "2768"
    },
    {
        "catalan": "davant",
        "english": "in front",
        "usageinwikipedia": "2760"
    },
    {
        "catalan": "mateixa",
        "english": "same",
        "usageinwikipedia": "2748"
    },
    {
        "catalan": "prop",
        "english": "close",
        "usageinwikipedia": "2732"
    },
    {
        "catalan": "moviment",
        "english": "motion",
        "usageinwikipedia": "2729"
    },
    {
        "catalan": "campionat",
        "english": "championship",
        "usageinwikipedia": "2728"
    },
    {
        "catalan": "octubre",
        "english": "October",
        "usageinwikipedia": "2719"
    },
    {
        "catalan": "serra",
        "english": "Mountain range",
        "usageinwikipedia": "2717"
    },
    {
        "catalan": "comte",
        "english": "late",
        "usageinwikipedia": "2716"
    },
    {
        "catalan": "sovint",
        "english": "Often",
        "usageinwikipedia": "2707"
    },
    {
        "catalan": "càrrec",
        "english": "charge",
        "usageinwikipedia": "2697"
    },
    {
        "catalan": "cos",
        "english": "body",
        "usageinwikipedia": "2685"
    },
    {
        "catalan": "costat",
        "english": "side",
        "usageinwikipedia": "2684"
    },
    {
        "catalan": "reial",
        "english": "royal",
        "usageinwikipedia": "2683"
    },
    {
        "catalan": "agost",
        "english": "August",
        "usageinwikipedia": "2661"
    },
    {
        "catalan": "llarg",
        "english": "long",
        "usageinwikipedia": "2661"
    },
    {
        "catalan": "filla",
        "english": "daughter",
        "usageinwikipedia": "2659"
    },
    {
        "catalan": "suport",
        "english": "back",
        "usageinwikipedia": "2654"
    },
    {
        "catalan": "forces",
        "english": "forces",
        "usageinwikipedia": "2651"
    },
    {
        "catalan": "municipi",
        "english": "municipality",
        "usageinwikipedia": "2651"
    },
    {
        "catalan": "maig",
        "english": "May",
        "usageinwikipedia": "2650"
    },
    {
        "catalan": "total",
        "english": "total",
        "usageinwikipedia": "2647"
    },
    {
        "catalan": "moltes",
        "english": "many",
        "usageinwikipedia": "2641"
    },
    {
        "catalan": "especialment",
        "english": "especially",
        "usageinwikipedia": "2640"
    },
    {
        "catalan": "civil",
        "english": "civilian",
        "usageinwikipedia": "2636"
    },
    {
        "catalan": "popular",
        "english": "popular",
        "usageinwikipedia": "2630"
    },
    {
        "catalan": "ells",
        "english": "they",
        "usageinwikipedia": "2621"
    },
    {
        "catalan": "juny",
        "english": "June",
        "usageinwikipedia": "2612"
    },
    {
        "catalan": "comarca",
        "english": "region",
        "usageinwikipedia": "2608"
    },
    {
        "catalan": "importants",
        "english": "important",
        "usageinwikipedia": "2608"
    },
    {
        "catalan": "tropes",
        "english": "troops",
        "usageinwikipedia": "2608"
    },
    {
        "catalan": "Itàlia",
        "english": "Italy",
        "usageinwikipedia": "2595"
    },
    {
        "catalan": "avui",
        "english": "today",
        "usageinwikipedia": "2594"
    },
    {
        "catalan": "or",
        "english": "gold",
        "usageinwikipedia": "2592"
    },
    {
        "catalan": "central",
        "english": "central",
        "usageinwikipedia": "2587"
    },
    {
        "catalan": "títol",
        "english": "title",
        "usageinwikipedia": "2576"
    },
    {
        "catalan": "estil",
        "english": "style",
        "usageinwikipedia": "2575"
    },
    {
        "catalan": "edat",
        "english": "age",
        "usageinwikipedia": "2572"
    },
    {
        "catalan": "abril",
        "english": "April",
        "usageinwikipedia": "2569"
    },
    {
        "catalan": "setembre",
        "english": "September",
        "usageinwikipedia": "2566"
    },
    {
        "catalan": "cop",
        "english": "beat",
        "usageinwikipedia": "2557"
    },
    {
        "catalan": "corona",
        "english": "crown",
        "usageinwikipedia": "2543"
    },
    {
        "catalan": "anar",
        "english": "to go",
        "usageinwikipedia": "2541"
    },
    {
        "catalan": "situat",
        "english": "location",
        "usageinwikipedia": "2537"
    },
    {
        "catalan": "resta",
        "english": "remainder",
        "usageinwikipedia": "2536"
    },
    {
        "catalan": "través",
        "english": "through",
        "usageinwikipedia": "2521"
    },
    {
        "catalan": "oest",
        "english": "West",
        "usageinwikipedia": "2517"
    },
    {
        "catalan": "català",
        "english": "Catalan",
        "usageinwikipedia": "2515"
    },
    {
        "catalan": "Aragó",
        "english": "Aragoon",
        "usageinwikipedia": "2510"
    },
    {
        "catalan": "espanyola",
        "english": "Spanish",
        "usageinwikipedia": "2510"
    },
    {
        "catalan": "principals",
        "english": "main",
        "usageinwikipedia": "2510"
    },
    {
        "catalan": "militar",
        "english": "military",
        "usageinwikipedia": "2507"
    },
    {
        "catalan": "estar",
        "english": "be",
        "usageinwikipedia": "2502"
    },
    {
        "catalan": "membre",
        "english": "member",
        "usageinwikipedia": "2499"
    },
    {
        "catalan": "cal",
        "english": "need",
        "usageinwikipedia": "2495"
    },
    {
        "catalan": "ciutats",
        "english": "cities",
        "usageinwikipedia": "2492"
    },
    {
        "catalan": "est",
        "english": "East",
        "usageinwikipedia": "2475"
    },
    {
        "catalan": "aquell",
        "english": "that",
        "usageinwikipedia": "2471"
    },
    {
        "catalan": "Madrid",
        "english": "Madrid",
        "usageinwikipedia": "2470"
    },
    {
        "catalan": "mare",
        "english": "mother",
        "usageinwikipedia": "2461"
    },
    {
        "catalan": "juntament",
        "english": "along",
        "usageinwikipedia": "2455"
    },
    {
        "catalan": "París",
        "english": "Paris",
        "usageinwikipedia": "2455"
    },
    {
        "catalan": "menys",
        "english": "fewer",
        "usageinwikipedia": "2441"
    },
    {
        "catalan": "v",
        "english": "v",
        "usageinwikipedia": "2437"
    },
    {
        "catalan": "següent",
        "english": "Next",
        "usageinwikipedia": "2377"
    },
    {
        "catalan": "internacional",
        "english": "international",
        "usageinwikipedia": "2373"
    },
    {
        "catalan": "IV",
        "english": "IV",
        "usageinwikipedia": "2370"
    },
    {
        "catalan": "construcció",
        "english": "construction",
        "usageinwikipedia": "2368"
    },
    {
        "catalan": "s",
        "english": "s",
        "usageinwikipedia": "2365"
    },
    {
        "catalan": "trobar",
        "english": "to find",
        "usageinwikipedia": "2363"
    },
    {
        "catalan": "gener",
        "english": "January",
        "usageinwikipedia": "2356"
    },
    {
        "catalan": "pas",
        "english": "step",
        "usageinwikipedia": "2356"
    },
    {
        "catalan": "pau",
        "english": "peace",
        "usageinwikipedia": "2354"
    },
    {
        "catalan": "èxit",
        "english": "success",
        "usageinwikipedia": "2338"
    },
    {
        "catalan": "francesa",
        "english": "frank",
        "usageinwikipedia": "2336"
    },
    {
        "catalan": "vall",
        "english": "valley",
        "usageinwikipedia": "2336"
    },
    {
        "catalan": "etc. 2331",
        "english": "etc. 2331"
    },
    {
        "catalan": "sempre",
        "english": "always",
        "usageinwikipedia": "2328"
    },
    {
        "catalan": "Lluís",
        "english": "Hoist",
        "usageinwikipedia": "2327"
    },
    {
        "catalan": "espècies",
        "english": "spices",
        "usageinwikipedia": "2323"
    },
    {
        "catalan": "obstant",
        "english": "I",
        "usageinwikipedia": "2317"
    },
    {
        "catalan": "ordre",
        "english": "warrant",
        "usageinwikipedia": "2307"
    },
    {
        "catalan": "conjunt",
        "english": "set",
        "usageinwikipedia": "2305"
    },
    {
        "catalan": "grups",
        "english": "groups",
        "usageinwikipedia": "2300"
    },
    {
        "catalan": "ús",
        "english": "use",
        "usageinwikipedia": "2300"
    },
    {
        "catalan": "membres",
        "english": "members",
        "usageinwikipedia": "2288"
    },
    {
        "catalan": "mitjançant",
        "english": "through",
        "usageinwikipedia": "2287"
    },
    {
        "catalan": "alt",
        "english": "high",
        "usageinwikipedia": "2279"
    },
    {
        "catalan": "antiga",
        "english": "late",
        "usageinwikipedia": "2279"
    },
    {
        "catalan": "vila",
        "english": "town",
        "usageinwikipedia": "2266"
    },
    {
        "catalan": "habitants",
        "english": "inhabitants",
        "usageinwikipedia": "2256"
    },
    {
        "catalan": "néixer",
        "english": "to be born",
        "usageinwikipedia": "2244"
    },
    {
        "catalan": "canvi",
        "english": "exchange",
        "usageinwikipedia": "2238"
    },
    {
        "catalan": "participar",
        "english": "join in",
        "usageinwikipedia": "2233"
    },
    {
        "catalan": "illes",
        "english": "islands",
        "usageinwikipedia": "2230"
    },
    {
        "catalan": "cinc",
        "english": "five",
        "usageinwikipedia": "2228"
    },
    {
        "catalan": "XIX",
        "english": "Nineteenthum",
        "usageinwikipedia": "2223"
    },
    {
        "catalan": "lliga",
        "english": "League",
        "usageinwikipedia": "2198"
    },
    {
        "catalan": "veure",
        "english": "I'll see",
        "usageinwikipedia": "2190"
    },
    {
        "catalan": "març",
        "english": "March",
        "usageinwikipedia": "2185"
    },
    {
        "catalan": "estudis",
        "english": "studies",
        "usageinwikipedia": "2182"
    },
    {
        "catalan": "sèrie",
        "english": "series",
        "usageinwikipedia": "2170"
    },
    {
        "catalan": "desembre",
        "english": "December",
        "usageinwikipedia": "2136"
    },
    {
        "catalan": "base",
        "english": "basis",
        "usageinwikipedia": "2134"
    },
    {
        "catalan": "anomenada",
        "english": "named",
        "usageinwikipedia": "2131"
    },
    {
        "catalan": "fills",
        "english": "children",
        "usageinwikipedia": "2122"
    },
    {
        "catalan": "n' 2117",
        "english": "n '2117"
    },
    {
        "catalan": "malgrat",
        "english": "anyhow",
        "usageinwikipedia": "2103"
    },
    {
        "catalan": "duc",
        "english": "duke",
        "usageinwikipedia": "2097"
    },
    {
        "catalan": "novembre",
        "english": "November",
        "usageinwikipedia": "2096"
    },
    {
        "catalan": "art",
        "english": "art",
        "usageinwikipedia": "2091"
    },
    {
        "catalan": "persones",
        "english": "people",
        "usageinwikipedia": "2085"
    },
    {
        "catalan": "sobretot",
        "english": "above all",
        "usageinwikipedia": "2083"
    },
    {
        "catalan": "ben",
        "english": "ben",
        "usageinwikipedia": "2081"
    },
    {
        "catalan": "tractat",
        "english": "treaty",
        "usageinwikipedia": "2079"
    },
    {
        "catalan": "front",
        "english": "front",
        "usageinwikipedia": "2073"
    },
    {
        "catalan": "crear",
        "english": "to create",
        "usageinwikipedia": "2065"
    },
    {
        "catalan": "elements",
        "english": "elements",
        "usageinwikipedia": "2054"
    },
    {
        "catalan": "troben",
        "english": "vent",
        "usageinwikipedia": "2048"
    },
    {
        "catalan": "alta",
        "english": "tall",
        "usageinwikipedia": "2044"
    },
    {
        "catalan": "premi",
        "english": "prize",
        "usageinwikipedia": "2044"
    },
    {
        "catalan": "escola",
        "english": "school",
        "usageinwikipedia": "2034"
    },
    {
        "catalan": "sinó",
        "english": "if not",
        "usageinwikipedia": "2030"
    },
    {
        "catalan": "espanyol",
        "english": "Spanish",
        "usageinwikipedia": "2029"
    },
    {
        "catalan": "victòria",
        "english": "victory",
        "usageinwikipedia": "2018"
    },
    {
        "catalan": "palau",
        "english": "palace",
        "usageinwikipedia": "2016"
    },
    {
        "catalan": "costa",
        "english": "coast",
        "usageinwikipedia": "2011"
    },
    {
        "catalan": "guanyar",
        "english": "to win",
        "usageinwikipedia": "2007"
    },
    {
        "catalan": "acabar",
        "english": "terminate",
        "usageinwikipedia": "2006"
    },
    {
        "catalan": "millor",
        "english": "better",
        "usageinwikipedia": "2003"
    },
    {
        "catalan": "Jaume",
        "english": "Jaume",
        "usageinwikipedia": "2001"
    },
    {
        "catalan": "tanmateix",
        "english": "however",
        "usageinwikipedia": "2001"
    },
    {
        "catalan": "finals",
        "english": "late",
        "usageinwikipedia": "1995"
    },
    {
        "catalan": "alemany",
        "english": "German",
        "usageinwikipedia": "1993"
    },
    {
        "catalan": "homes",
        "english": "men",
        "usageinwikipedia": "1986"
    },
    {
        "catalan": "principalment",
        "english": "chiefly",
        "usageinwikipedia": "1981"
    },
    {
        "catalan": "febrer",
        "english": "February",
        "usageinwikipedia": "1976"
    },
    {
        "catalan": "catalana",
        "english": "Catalan",
        "usageinwikipedia": "1971"
    },
    {
        "catalan": "terres",
        "english": "earth",
        "usageinwikipedia": "1969"
    },
    {
        "catalan": "ni",
        "english": "nor",
        "usageinwikipedia": "1967"
    },
    {
        "catalan": "consell",
        "english": "advice",
        "usageinwikipedia": "1952"
    },
    {
        "catalan": "segles",
        "english": "centuries",
        "usageinwikipedia": "1952"
    },
    {
        "catalan": "déu",
        "english": "ten",
        "usageinwikipedia": "1940"
    },
    {
        "catalan": "color",
        "english": "color",
        "usageinwikipedia": "1932"
    },
    {
        "catalan": "tan",
        "english": "so",
        "usageinwikipedia": "1931"
    },
    {
        "catalan": "c",
        "english": "c",
        "usageinwikipedia": "1927"
    },
    {
        "catalan": "partits",
        "english": "matches",
        "usageinwikipedia": "1925"
    },
    {
        "catalan": "copa",
        "english": "cup",
        "usageinwikipedia": "1924"
    },
    {
        "catalan": "coneguda",
        "english": "well-known",
        "usageinwikipedia": "1919"
    },
    {
        "catalan": "Josep",
        "english": "Josep",
        "usageinwikipedia": "1917"
    },
    {
        "catalan": "format",
        "english": "format",
        "usageinwikipedia": "1915"
    },
    {
        "catalan": "interior",
        "english": "interior",
        "usageinwikipedia": "1911"
    },
    {
        "catalan": "establir",
        "english": "to set",
        "usageinwikipedia": "1900"
    },
    {
        "catalan": "nivell",
        "english": "level",
        "usageinwikipedia": "1898"
    },
    {
        "catalan": "desenvolupament",
        "english": "development",
        "usageinwikipedia": "1886"
    },
    {
        "catalan": "Felip",
        "english": "Phoney",
        "usageinwikipedia": "1886"
    },
    {
        "catalan": "paper",
        "english": "paper",
        "usageinwikipedia": "1880"
    },
    {
        "catalan": "gairebé",
        "english": "almost",
        "usageinwikipedia": "1874"
    },
    {
        "catalan": "vers",
        "english": "verse",
        "usageinwikipedia": "1874"
    },
    {
        "catalan": "oficial",
        "english": "officer",
        "usageinwikipedia": "1870"
    },
    {
        "catalan": "príncep",
        "english": "prince",
        "usageinwikipedia": "1870"
    },
    {
        "catalan": "voltant",
        "english": "around",
        "usageinwikipedia": "1865"
    },
    {
        "catalan": "posició",
        "english": "position",
        "usageinwikipedia": "1863"
    },
    {
        "catalan": "Castella",
        "english": "Castilla",
        "usageinwikipedia": "1856"
    },
    {
        "catalan": "treball",
        "english": "work",
        "usageinwikipedia": "1850"
    },
    {
        "catalan": "aleshores",
        "english": "then",
        "usageinwikipedia": "1844"
    },
    {
        "catalan": "deixar",
        "english": "to leave",
        "usageinwikipedia": "1841"
    },
    {
        "catalan": "municipal",
        "english": "municipal",
        "usageinwikipedia": "1837"
    },
    {
        "catalan": "procés",
        "english": "trial",
        "usageinwikipedia": "1835"
    },
    {
        "catalan": "direcció",
        "english": "management",
        "usageinwikipedia": "1829"
    },
    {
        "catalan": "petit",
        "english": "small",
        "usageinwikipedia": "1829"
    },
    {
        "catalan": "dret",
        "english": "right",
        "usageinwikipedia": "1825"
    },
    {
        "catalan": "ocupar",
        "english": "employ",
        "usageinwikipedia": "1825"
    },
    {
        "catalan": "ministre",
        "english": "minister",
        "usageinwikipedia": "1824"
    },
    {
        "catalan": "teoria",
        "english": "theory",
        "usageinwikipedia": "1823"
    },
    {
        "catalan": "primeres",
        "english": "First",
        "usageinwikipedia": "1821"
    },
    {
        "catalan": "construir",
        "english": "build",
        "usageinwikipedia": "1820"
    },
    {
        "catalan": "gràcies",
        "english": "thanks",
        "usageinwikipedia": "1819"
    },
    {
        "catalan": "mitjana",
        "english": "medium",
        "usageinwikipedia": "1806"
    },
    {
        "catalan": "qualsevol",
        "english": "any",
        "usageinwikipedia": "1805"
    },
    {
        "catalan": "superior",
        "english": "superior",
        "usageinwikipedia": "1792"
    },
    {
        "catalan": "occidental",
        "english": "western",
        "usageinwikipedia": "1790"
    },
    {
        "catalan": "estudiar",
        "english": "to study",
        "usageinwikipedia": "1789"
    },
    {
        "catalan": "servir",
        "english": "to serve",
        "usageinwikipedia": "1787"
    },
    {
        "catalan": "únic",
        "english": "unique",
        "usageinwikipedia": "1787"
    },
    {
        "catalan": "seria",
        "english": "it would be",
        "usageinwikipedia": "1785"
    },
    {
        "catalan": "cultura",
        "english": "culture",
        "usageinwikipedia": "1775"
    },
    {
        "catalan": "olímpics",
        "english": "almond",
        "usageinwikipedia": "1772"
    },
    {
        "catalan": "anglès",
        "english": "english",
        "usageinwikipedia": "1771"
    },
    {
        "catalan": "escut",
        "english": "shield",
        "usageinwikipedia": "1760"
    },
    {
        "catalan": "influència",
        "english": "influence",
        "usageinwikipedia": "1753"
    },
    {
        "catalan": "of",
        "english": "itary",
        "usageinwikipedia": "1746"
    },
    {
        "catalan": "romans",
        "english": "roman",
        "usageinwikipedia": "1746"
    },
    {
        "catalan": "monestir",
        "english": "monastery",
        "usageinwikipedia": "1738"
    },
    {
        "catalan": "carrera",
        "english": "career",
        "usageinwikipedia": "1737"
    },
    {
        "catalan": "esquerra",
        "english": "left handed",
        "usageinwikipedia": "1733"
    },
    {
        "catalan": "apareix",
        "english": "appears",
        "usageinwikipedia": "1726"
    },
    {
        "catalan": "mantenir",
        "english": "keep",
        "usageinwikipedia": "1726"
    },
    {
        "catalan": "oriental",
        "english": "oriental",
        "usageinwikipedia": "1726"
    },
    {
        "catalan": "propi",
        "english": "own",
        "usageinwikipedia": "1725"
    },
    {
        "catalan": "comtat",
        "english": "county",
        "usageinwikipedia": "1724"
    },
    {
        "catalan": "director",
        "english": "conductor",
        "usageinwikipedia": "1721"
    },
    {
        "catalan": "superfície",
        "english": "surface",
        "usageinwikipedia": "1721"
    },
    {
        "catalan": "gènere",
        "english": "genre",
        "usageinwikipedia": "1716"
    },
    {
        "catalan": "XX",
        "english": "Xx",
        "usageinwikipedia": "1711"
    },
    {
        "catalan": "sis",
        "english": "six",
        "usageinwikipedia": "1708"
    },
    {
        "catalan": "jove",
        "english": "young",
        "usageinwikipedia": "1702"
    },
    {
        "catalan": "mans",
        "english": "hands",
        "usageinwikipedia": "1701"
    },
    {
        "catalan": "probablement",
        "english": "probably",
        "usageinwikipedia": "1698"
    },
    {
        "catalan": "producció",
        "english": "yield",
        "usageinwikipedia": "1696"
    },
    {
        "catalan": "districte",
        "english": "district",
        "usageinwikipedia": "1692"
    },
    {
        "catalan": "milions",
        "english": "million",
        "usageinwikipedia": "1692"
    },
    {
        "catalan": "x",
        "english": "X",
        "usageinwikipedia": "1683"
    },
    {
        "catalan": "Jordi",
        "english": "George",
        "usageinwikipedia": "1674"
    },
    {
        "catalan": "grec",
        "english": "grain",
        "usageinwikipedia": "1673"
    },
    {
        "catalan": "Anglaterra",
        "english": "England",
        "usageinwikipedia": "1671"
    },
    {
        "catalan": "pla",
        "english": "layout",
        "usageinwikipedia": "1669"
    },
    {
        "catalan": "diu",
        "english": "he says",
        "usageinwikipedia": "1655"
    },
    {
        "catalan": "servei",
        "english": "service",
        "usageinwikipedia": "1649"
    },
    {
        "catalan": "formació",
        "english": "formation",
        "usageinwikipedia": "1647"
    },
    {
        "catalan": "energia",
        "english": "energy",
        "usageinwikipedia": "1640"
    },
    {
        "catalan": "entrar",
        "english": "to enter",
        "usageinwikipedia": "1640"
    },
    {
        "catalan": "via",
        "english": "track",
        "usageinwikipedia": "1640"
    },
    {
        "catalan": "llum",
        "english": "light",
        "usageinwikipedia": "1638"
    },
    {
        "catalan": "estiu",
        "english": "summer",
        "usageinwikipedia": "1637"
    },
    {
        "catalan": "espai",
        "english": "space",
        "usageinwikipedia": "1636"
    },
    {
        "catalan": "prendre",
        "english": "take",
        "usageinwikipedia": "1632"
    },
    {
        "catalan": "Alfons",
        "english": "Background",
        "usageinwikipedia": "1628"
    },
    {
        "catalan": "estructura",
        "english": "structure",
        "usageinwikipedia": "1625"
    },
    {
        "catalan": "relació",
        "english": "relationship",
        "usageinwikipedia": "1615"
    },
    {
        "catalan": "mai",
        "english": "ever",
        "usageinwikipedia": "1613"
    },
    {
        "catalan": "activitat",
        "english": "activity",
        "usageinwikipedia": "1611"
    },
    {
        "catalan": "teatre",
        "english": "theater",
        "usageinwikipedia": "1610"
    },
    {
        "catalan": "massa",
        "english": "too",
        "usageinwikipedia": "1604"
    },
    {
        "catalan": "San",
        "english": "San",
        "usageinwikipedia": "1603"
    },
    {
        "catalan": "quedar",
        "english": "meet up",
        "usageinwikipedia": "1599"
    },
    {
        "catalan": "port",
        "english": "port",
        "usageinwikipedia": "1597"
    },
    {
        "catalan": "romà",
        "english": "roman",
        "usageinwikipedia": "1596"
    },
    {
        "catalan": "social",
        "english": "social",
        "usageinwikipedia": "1596"
    },
    {
        "catalan": "Enric",
        "english": "Enric",
        "usageinwikipedia": "1594"
    },
    {
        "catalan": "natural",
        "english": "natural",
        "usageinwikipedia": "1594"
    },
    {
        "catalan": "situació",
        "english": "situation",
        "usageinwikipedia": "1590"
    },
    {
        "catalan": "convertir",
        "english": "convert",
        "usageinwikipedia": "1589"
    },
    {
        "catalan": "creació",
        "english": "creation",
        "usageinwikipedia": "1589"
    },
    {
        "catalan": "parlament",
        "english": "Parliament",
        "usageinwikipedia": "1582"
    },
    {
        "catalan": "funció",
        "english": "function",
        "usageinwikipedia": "1577"
    },
    {
        "catalan": "papa",
        "english": "dad, father",
        "usageinwikipedia": "1574"
    },
    {
        "catalan": "defensa",
        "english": "hedge",
        "usageinwikipedia": "1573"
    },
    {
        "catalan": "revolució",
        "english": "revolution",
        "usageinwikipedia": "1571"
    },
    {
        "catalan": "societat",
        "english": "society",
        "usageinwikipedia": "1571"
    },
    {
        "catalan": "territoris",
        "english": "Territories",
        "usageinwikipedia": "1570"
    },
    {
        "catalan": "plaça",
        "english": "square",
        "usageinwikipedia": "1562"
    },
    {
        "catalan": "mesos",
        "english": "months",
        "usageinwikipedia": "1559"
    },
    {
        "catalan": "dècada",
        "english": "decade",
        "usageinwikipedia": "1556"
    },
    {
        "catalan": "edifici",
        "english": "building",
        "usageinwikipedia": "1555"
    },
    {
        "catalan": "restes",
        "english": "remains",
        "usageinwikipedia": "1551"
    },
    {
        "catalan": "Amèrica",
        "english": "America",
        "usageinwikipedia": "1550"
    },
    {
        "catalan": "control",
        "english": "check",
        "usageinwikipedia": "1549"
    },
    {
        "catalan": "generals",
        "english": "generals",
        "usageinwikipedia": "1546"
    },
    {
        "catalan": "llibre",
        "english": "book",
        "usageinwikipedia": "1542"
    },
    {
        "catalan": "categoria",
        "english": "category",
        "usageinwikipedia": "1541"
    },
    {
        "catalan": "pobles",
        "english": "towns",
        "usageinwikipedia": "1538"
    },
    {
        "catalan": "selecció",
        "english": "selection",
        "usageinwikipedia": "1535"
    },
    {
        "catalan": "dona",
        "english": "woman",
        "usageinwikipedia": "1528"
    },
    {
        "catalan": "tracta",
        "english": "is about",
        "usageinwikipedia": "1528"
    },
    {
        "catalan": "mes",
        "english": "month",
        "usageinwikipedia": "1527"
    },
    {
        "catalan": "llei",
        "english": "law",
        "usageinwikipedia": "1525"
    },
    {
        "catalan": "unit",
        "english": "united",
        "usageinwikipedia": "1523"
    },
    {
        "catalan": "porta",
        "english": "gate",
        "usageinwikipedia": "1520"
    },
    {
        "catalan": "departament",
        "english": "department",
        "usageinwikipedia": "1518"
    },
    {
        "catalan": "museu",
        "english": "Museum",
        "usageinwikipedia": "1517"
    },
    {
        "catalan": "bisbe",
        "english": "bishop",
        "usageinwikipedia": "1514"
    },
    {
        "catalan": "conca",
        "english": "basin",
        "usageinwikipedia": "1510"
    },
    {
        "catalan": "fan",
        "english": "do",
        "usageinwikipedia": "1509"
    },
    {
        "catalan": "barranc",
        "english": "ravine",
        "usageinwikipedia": "1508"
    },
    {
        "catalan": "escriure",
        "english": "write",
        "usageinwikipedia": "1508"
    },
    {
        "catalan": "pont",
        "english": "bridge",
        "usageinwikipedia": "1507"
    },
    {
        "catalan": "animals",
        "english": "animals",
        "usageinwikipedia": "1506"
    },
    {
        "catalan": "possible",
        "english": "possible",
        "usageinwikipedia": "1504"
    },
    {
        "catalan": "parts",
        "english": "parts",
        "usageinwikipedia": "1493"
    },
    {
        "catalan": "zones",
        "english": "areas",
        "usageinwikipedia": "1487"
    },
    {
        "catalan": "principis",
        "english": "principles",
        "usageinwikipedia": "1486"
    },
    {
        "catalan": "reina",
        "english": "queen",
        "usageinwikipedia": "1481"
    },
    {
        "catalan": "estació",
        "english": "station",
        "usageinwikipedia": "1477"
    },
    {
        "catalan": "Francesc",
        "english": "Frameworks",
        "usageinwikipedia": "1470"
    },
    {
        "catalan": "independència",
        "english": "independence",
        "usageinwikipedia": "1469"
    },
    {
        "catalan": "planta",
        "english": "sole",
        "usageinwikipedia": "1468"
    },
    {
        "catalan": "Ramon",
        "english": "Ramon",
        "usageinwikipedia": "1464"
    },
    {
        "catalan": "única",
        "english": "unique",
        "usageinwikipedia": "1463"
    },
    {
        "catalan": "muntanya",
        "english": "mountain",
        "usageinwikipedia": "1459"
    },
    {
        "catalan": "orde",
        "english": "order",
        "usageinwikipedia": "1459"
    },
    {
        "catalan": "aviat",
        "english": "early",
        "usageinwikipedia": "1457"
    },
    {
        "catalan": "real",
        "english": "actual",
        "usageinwikipedia": "1456"
    },
    {
        "catalan": "espècie",
        "english": "species",
        "usageinwikipedia": "1455"
    },
    {
        "catalan": "presència",
        "english": "presence",
        "usageinwikipedia": "1449"
    },
    {
        "catalan": "produir",
        "english": "yield",
        "usageinwikipedia": "1447"
    },
    {
        "catalan": "realitzar",
        "english": "do",
        "usageinwikipedia": "1447"
    },
    {
        "catalan": "torre",
        "english": "tower",
        "usageinwikipedia": "1443"
    },
    {
        "catalan": "Àfrica",
        "english": "Africa",
        "usageinwikipedia": "1442"
    },
    {
        "catalan": "tercer",
        "english": "third",
        "usageinwikipedia": "1442"
    },
    {
        "catalan": "XVIII",
        "english": "XVIII",
        "usageinwikipedia": "1441"
    },
    {
        "catalan": "romana",
        "english": "rate",
        "usageinwikipedia": "1434"
    },
    {
        "catalan": "punts",
        "english": "dots",
        "usageinwikipedia": "1427"
    },
    {
        "catalan": "polític",
        "english": "politician",
        "usageinwikipedia": "1426"
    },
    {
        "catalan": "quantitat",
        "english": "quantity",
        "usageinwikipedia": "1426"
    },
    {
        "catalan": "sembla",
        "english": "looks like",
        "usageinwikipedia": "1423"
    },
    {
        "catalan": "reis",
        "english": "kings",
        "usageinwikipedia": "1421"
    },
    {
        "catalan": "resultat",
        "english": "outcome",
        "usageinwikipedia": "1421"
    },
    {
        "catalan": "y",
        "english": "y",
        "usageinwikipedia": "1421"
    },
    {
        "catalan": "deu",
        "english": "ten",
        "usageinwikipedia": "1420"
    },
    {
        "catalan": "domini",
        "english": "control",
        "usageinwikipedia": "1413"
    },
    {
        "catalan": "esdevenir",
        "english": "become",
        "usageinwikipedia": "1412"
    },
    {
        "catalan": "estudi",
        "english": "study",
        "usageinwikipedia": "1411"
    },
    {
        "catalan": "lluita",
        "english": "match",
        "usageinwikipedia": "1404"
    },
    {
        "catalan": "temple",
        "english": "temple",
        "usageinwikipedia": "1404"
    },
    {
        "catalan": "meitat",
        "english": "half",
        "usageinwikipedia": "1403"
    },
    {
        "catalan": "últim",
        "english": "last",
        "usageinwikipedia": "1402"
    },
    {
        "catalan": "acord",
        "english": "agreement",
        "usageinwikipedia": "1401"
    },
    {
        "catalan": "menor",
        "english": "less",
        "usageinwikipedia": "1401"
    },
    {
        "catalan": "Rússia",
        "english": "Russia",
        "usageinwikipedia": "1394"
    },
    {
        "catalan": "generalment",
        "english": "generally",
        "usageinwikipedia": "1393"
    },
    {
        "catalan": "proves",
        "english": "tests",
        "usageinwikipedia": "1389"
    },
    {
        "catalan": "drets",
        "english": "fee",
        "usageinwikipedia": "1386"
    },
    {
        "catalan": "versió",
        "english": "version",
        "usageinwikipedia": "1386"
    },
    {
        "catalan": "font",
        "english": "source",
        "usageinwikipedia": "1383"
    },
    {
        "catalan": "set",
        "english": "seven",
        "usageinwikipedia": "1381"
    },
    {
        "catalan": "degut",
        "english": "due",
        "usageinwikipedia": "1378"
    },
    {
        "catalan": "enviar",
        "english": "to send",
        "usageinwikipedia": "1376"
    },
    {
        "catalan": "portar",
        "english": "bring",
        "usageinwikipedia": "1376"
    },
    {
        "catalan": "importància",
        "english": "importance",
        "usageinwikipedia": "1375"
    },
    {
        "catalan": "llocs",
        "english": "places",
        "usageinwikipedia": "1375"
    },
    {
        "catalan": "tron",
        "english": "throne",
        "usageinwikipedia": "1375"
    },
    {
        "catalan": "nous",
        "english": "new",
        "usageinwikipedia": "1373"
    },
    {
        "catalan": "seguir",
        "english": "follow",
        "usageinwikipedia": "1373"
    },
    {
        "catalan": "treballar",
        "english": "to work",
        "usageinwikipedia": "1371"
    },
    {
        "catalan": "b",
        "english": "b",
        "usageinwikipedia": "1370"
    },
    {
        "catalan": "àrea",
        "english": "area",
        "usageinwikipedia": "1367"
    },
    {
        "catalan": "catedral",
        "english": "cathedral",
        "usageinwikipedia": "1362"
    },
    {
        "catalan": "model",
        "english": "model",
        "usageinwikipedia": "1360"
    },
    {
        "catalan": "comandament",
        "english": "command",
        "usageinwikipedia": "1359"
    },
    {
        "catalan": "petita",
        "english": "small",
        "usageinwikipedia": "1359"
    },
    {
        "catalan": "Portugal",
        "english": "Portugal",
        "usageinwikipedia": "1356"
    },
    {
        "catalan": "normalment",
        "english": "normally",
        "usageinwikipedia": "1348"
    },
    {
        "catalan": "estaven",
        "english": "they were",
        "usageinwikipedia": "1347"
    },
    {
        "catalan": "perdre",
        "english": "to lose",
        "usageinwikipedia": "1345"
    },
    {
        "catalan": "XIII",
        "english": "XIII",
        "usageinwikipedia": "1344"
    },
    {
        "catalan": "cursa",
        "english": "race",
        "usageinwikipedia": "1341"
    },
    {
        "catalan": "f",
        "english": "f",
        "usageinwikipedia": "1341"
    },
    {
        "catalan": "organització",
        "english": "organization",
        "usageinwikipedia": "1340"
    },
    {
        "catalan": "seues",
        "english": "her",
        "usageinwikipedia": "1333"
    },
    {
        "catalan": "alemanys",
        "english": "Germans",
        "usageinwikipedia": "1332"
    },
    {
        "catalan": "tercera",
        "english": "third",
        "usageinwikipedia": "1331"
    },
    {
        "catalan": "decidir",
        "english": "decide",
        "usageinwikipedia": "1330"
    },
    {
        "catalan": "ella",
        "english": "it",
        "usageinwikipedia": "1329"
    },
    {
        "catalan": "jugar",
        "english": "play",
        "usageinwikipedia": "1326"
    },
    {
        "catalan": "classe",
        "english": "kind",
        "usageinwikipedia": "1323"
    },
    {
        "catalan": "pròpia",
        "english": "own",
        "usageinwikipedia": "1322"
    },
    {
        "catalan": "dinastia",
        "english": "dynasty",
        "usageinwikipedia": "1321"
    },
    {
        "catalan": "cantó",
        "english": "corner",
        "usageinwikipedia": "1320"
    },
    {
        "catalan": "Egipte",
        "english": "Egypt",
        "usageinwikipedia": "1318"
    },
    {
        "catalan": "soviètica",
        "english": "Soviet",
        "usageinwikipedia": "1318"
    },
    {
        "catalan": "companyia",
        "english": "company",
        "usageinwikipedia": "1317"
    },
    {
        "catalan": "valencià",
        "english": "Valencia",
        "usageinwikipedia": "1315"
    },
    {
        "catalan": "projecte",
        "english": "project",
        "usageinwikipedia": "1311"
    },
    {
        "catalan": "viure",
        "english": "to live",
        "usageinwikipedia": "1310"
    },
    {
        "catalan": "antics",
        "english": "old",
        "usageinwikipedia": "1308"
    },
    {
        "catalan": "musical",
        "english": "musical",
        "usageinwikipedia": "1308"
    },
    {
        "catalan": "XVI",
        "english": "XVI",
        "usageinwikipedia": "1308"
    },
    {
        "catalan": "nucli",
        "english": "core",
        "usageinwikipedia": "1306"
    },
    {
        "catalan": "atac",
        "english": "attack",
        "usageinwikipedia": "1305"
    },
    {
        "catalan": "noves",
        "english": "new",
        "usageinwikipedia": "1304"
    },
    {
        "catalan": "home",
        "english": "man",
        "usageinwikipedia": "1303"
    },
    {
        "catalan": "sistemes",
        "english": "systems",
        "usageinwikipedia": "1298"
    },
    {
        "catalan": "comunitat",
        "english": "community",
        "usageinwikipedia": "1296"
    },
    {
        "catalan": "tradició",
        "english": "tradition",
        "usageinwikipedia": "1296"
    },
    {
        "catalan": "màxima",
        "english": "maximum",
        "usageinwikipedia": "1294"
    },
    {
        "catalan": "Ferran",
        "english": "Ferran",
        "usageinwikipedia": "1292"
    },
    {
        "catalan": "l",
        "english": "l",
        "usageinwikipedia": "1291"
    },
    {
        "catalan": "socialista",
        "english": "socialist",
        "usageinwikipedia": "1290"
    },
    {
        "catalan": "unitat",
        "english": "unit",
        "usageinwikipedia": "1289"
    },
    {
        "catalan": "baixa",
        "english": "low",
        "usageinwikipedia": "1287"
    },
    {
        "catalan": "Guillem",
        "english": "William",
        "usageinwikipedia": "1287"
    },
    {
        "catalan": "escollit",
        "english": "chosen",
        "usageinwikipedia": "1286"
    },
    {
        "catalan": "ambdós",
        "english": "both",
        "usageinwikipedia": "1285"
    },
    {
        "catalan": "Àustria",
        "english": "Austria",
        "usageinwikipedia": "1284"
    },
    {
        "catalan": "local",
        "english": "local",
        "usageinwikipedia": "1283"
    },
    {
        "catalan": "baix",
        "english": "low",
        "usageinwikipedia": "1282"
    },
    {
        "catalan": "municipis",
        "english": "mizes",
        "usageinwikipedia": "1277"
    },
    {
        "catalan": "conté",
        "english": "contains",
        "usageinwikipedia": "1276"
    },
    {
        "catalan": "llatí",
        "english": "Latin",
        "usageinwikipedia": "1273"
    },
    {
        "catalan": "caràcter",
        "english": "nature",
        "usageinwikipedia": "1272"
    },
    {
        "catalan": "tenien",
        "english": "were",
        "usageinwikipedia": "1272"
    },
    {
        "catalan": "carrer",
        "english": "Street",
        "usageinwikipedia": "1270"
    },
    {
        "catalan": "Grècia",
        "english": "Greece",
        "usageinwikipedia": "1269"
    },
    {
        "catalan": "inclou",
        "english": "includes",
        "usageinwikipedia": "1269"
    },
    {
        "catalan": "representació",
        "english": "representation",
        "usageinwikipedia": "1269"
    },
    {
        "catalan": "formen",
        "english": "they form",
        "usageinwikipedia": "1267"
    },
    {
        "catalan": "bona",
        "english": "good",
        "usageinwikipedia": "1266"
    },
    {
        "catalan": "governador",
        "english": "governor",
        "usageinwikipedia": "1266"
    },
    {
        "catalan": "VI",
        "english": "Wine",
        "usageinwikipedia": "1265"
    },
    {
        "catalan": "son",
        "english": "sleep",
        "usageinwikipedia": "1264"
    },
    {
        "catalan": "catalans",
        "english": "Catalans",
        "usageinwikipedia": "1262"
    },
    {
        "catalan": "data",
        "english": "date",
        "usageinwikipedia": "1262"
    },
    {
        "catalan": "llengües",
        "english": "tongues",
        "usageinwikipedia": "1259"
    },
    {
        "catalan": "comandant",
        "english": "commander",
        "usageinwikipedia": "1258"
    },
    {
        "catalan": "marc",
        "english": "March",
        "usageinwikipedia": "1257"
    },
    {
        "catalan": "disc",
        "english": "disk",
        "usageinwikipedia": "1253"
    },
    {
        "catalan": "flota",
        "english": "fleet",
        "usageinwikipedia": "1252"
    },
    {
        "catalan": "lliure",
        "english": "free",
        "usageinwikipedia": "1252"
    },
    {
        "catalan": "congrés",
        "english": "congress",
        "usageinwikipedia": "1251"
    },
    {
        "catalan": "mesura",
        "english": "measure",
        "usageinwikipedia": "1250"
    },
    {
        "catalan": "presenta",
        "english": "presents",
        "usageinwikipedia": "1245"
    },
    {
        "catalan": "barri",
        "english": "neighborhood",
        "usageinwikipedia": "1243"
    },
    {
        "catalan": "britànic",
        "english": "British",
        "usageinwikipedia": "1243"
    },
    {
        "catalan": "Londres",
        "english": "London",
        "usageinwikipedia": "1242"
    },
    {
        "catalan": "igual",
        "english": "match",
        "usageinwikipedia": "1241"
    },
    {
        "catalan": "passa",
        "english": "pass",
        "usageinwikipedia": "1241"
    },
    {
        "catalan": "fi",
        "english": "the end",
        "usageinwikipedia": "1240"
    },
    {
        "catalan": "acció",
        "english": "act",
        "usageinwikipedia": "1239"
    },
    {
        "catalan": "diferència",
        "english": "difference",
        "usageinwikipedia": "1239"
    },
    {
        "catalan": "permetre",
        "english": "to allow",
        "usageinwikipedia": "1239"
    },
    {
        "catalan": "e",
        "english": "e",
        "usageinwikipedia": "1238"
    },
    {
        "catalan": "joc",
        "english": "play",
        "usageinwikipedia": "1238"
    },
    {
        "catalan": "regions",
        "english": "regions",
        "usageinwikipedia": "1237"
    },
    {
        "catalan": "comença",
        "english": "begins",
        "usageinwikipedia": "1235"
    },
    {
        "catalan": "campanya",
        "english": "campaign",
        "usageinwikipedia": "1233"
    },
    {
        "catalan": "junt",
        "english": "there",
        "usageinwikipedia": "1231"
    },
    {
        "catalan": "Mèxic",
        "english": "Mexico",
        "usageinwikipedia": "1227"
    },
    {
        "catalan": "dóna",
        "english": "give",
        "usageinwikipedia": "1223"
    },
    {
        "catalan": "províncies",
        "english": "provinces",
        "usageinwikipedia": "1223"
    },
    {
        "catalan": "utilitza",
        "english": "it uses",
        "usageinwikipedia": "1221"
    },
    {
        "catalan": "aquells",
        "english": "those",
        "usageinwikipedia": "1219"
    },
    {
        "catalan": "petits",
        "english": "small",
        "usageinwikipedia": "1219"
    },
    {
        "catalan": "competició",
        "english": "competition",
        "usageinwikipedia": "1217"
    },
    {
        "catalan": "anterior",
        "english": "former",
        "usageinwikipedia": "1215"
    },
    {
        "catalan": "permet",
        "english": "allows",
        "usageinwikipedia": "1215"
    },
    {
        "catalan": "princesa",
        "english": "princess",
        "usageinwikipedia": "1214"
    },
    {
        "catalan": "europea",
        "english": "European",
        "usageinwikipedia": "1213"
    },
    {
        "catalan": "blanc",
        "english": "white",
        "usageinwikipedia": "1211"
    },
    {
        "catalan": "principi",
        "english": "principle",
        "usageinwikipedia": "1210"
    },
    {
        "catalan": "cort",
        "english": "court",
        "usageinwikipedia": "1209"
    },
    {
        "catalan": "formes",
        "english": "shaping",
        "usageinwikipedia": "1208"
    },
    {
        "catalan": "continuar",
        "english": "continue",
        "usageinwikipedia": "1207"
    },
    {
        "catalan": "armes",
        "english": "arms",
        "usageinwikipedia": "1206"
    },
    {
        "catalan": "diputat",
        "english": "deputy",
        "usageinwikipedia": "1205"
    },
    {
        "catalan": "honor",
        "english": "honor",
        "usageinwikipedia": "1203"
    },
    {
        "catalan": "matrimoni",
        "english": "marriage",
        "usageinwikipedia": "1202"
    },
    {
        "catalan": "elles",
        "english": "them",
        "usageinwikipedia": "1201"
    },
    {
        "catalan": "original",
        "english": "original",
        "usageinwikipedia": "1199"
    },
    {
        "catalan": "objectiu",
        "english": "goal",
        "usageinwikipedia": "1198"
    },
    {
        "catalan": "francesos",
        "english": "French",
        "usageinwikipedia": "1197"
    },
    {
        "catalan": "posar",
        "english": "to put",
        "usageinwikipedia": "1197"
    },
    {
        "catalan": "sigui",
        "english": "be",
        "usageinwikipedia": "1197"
    },
    {
        "catalan": "problemes",
        "english": "Problems",
        "usageinwikipedia": "1194"
    },
    {
        "catalan": "vots",
        "english": "votes",
        "usageinwikipedia": "1192"
    },
    {
        "catalan": "aproximadament",
        "english": "about",
        "usageinwikipedia": "1190"
    },
    {
        "catalan": "economia",
        "english": "economy",
        "usageinwikipedia": "1190"
    },
    {
        "catalan": "Mallorca",
        "english": "Mallorca",
        "usageinwikipedia": "1190"
    },
    {
        "catalan": "règim",
        "english": "regime",
        "usageinwikipedia": "1189"
    },
    {
        "catalan": "Antoni",
        "english": "Antoni",
        "usageinwikipedia": "1188"
    },
    {
        "catalan": "capella",
        "english": "chapel",
        "usageinwikipedia": "1188"
    },
    {
        "catalan": "Bretanya",
        "english": "Brittany",
        "usageinwikipedia": "1187"
    },
    {
        "catalan": "dreta",
        "english": "right",
        "usageinwikipedia": "1187"
    },
    {
        "catalan": "fundar",
        "english": "founded",
        "usageinwikipedia": "1186"
    },
    {
        "catalan": "Generalitat",
        "english": "Generality",
        "usageinwikipedia": "1184"
    },
    {
        "catalan": "frontera",
        "english": "border",
        "usageinwikipedia": "1182"
    },
    {
        "catalan": "funcions",
        "english": "functions",
        "usageinwikipedia": "1180"
    },
    {
        "catalan": "Xina",
        "english": "China",
        "usageinwikipedia": "1179"
    },
    {
        "catalan": "iniciar",
        "english": "start",
        "usageinwikipedia": "1178"
    },
    {
        "catalan": "referència",
        "english": "reference",
        "usageinwikipedia": "1178"
    },
    {
        "catalan": "'n",
        "english": "ha",
        "usageinwikipedia": "1176"
    },
    {
        "catalan": "fora",
        "english": "abroad",
        "usageinwikipedia": "1176"
    },
    {
        "catalan": "utilitzar",
        "english": "use",
        "usageinwikipedia": "1176"
    },
    {
        "catalan": "península",
        "english": "Peninsula",
        "usageinwikipedia": "1175"
    },
    {
        "catalan": "nombrosos",
        "english": "numerous",
        "usageinwikipedia": "1174"
    },
    {
        "catalan": "conquesta",
        "english": "conquest",
        "usageinwikipedia": "1171"
    },
    {
        "catalan": "York",
        "english": "York",
        "usageinwikipedia": "1170"
    },
    {
        "catalan": "Alexandre",
        "english": "Alexander",
        "usageinwikipedia": "1168"
    },
    {
        "catalan": "casos",
        "english": "cases",
        "usageinwikipedia": "1168"
    },
    {
        "catalan": "independent",
        "english": "independent",
        "usageinwikipedia": "1168"
    },
    {
        "catalan": "tradicional",
        "english": "traditional",
        "usageinwikipedia": "1166"
    },
    {
        "catalan": "relacions",
        "english": "intercourse",
        "usageinwikipedia": "1163"
    },
    {
        "catalan": "soldats",
        "english": "soldiers",
        "usageinwikipedia": "1162"
    },
    {
        "catalan": "podia",
        "english": "could",
        "usageinwikipedia": "1160"
    },
    {
        "catalan": "temporades",
        "english": "seasons",
        "usageinwikipedia": "1159"
    },
    {
        "catalan": "mestre",
        "english": "teacher",
        "usageinwikipedia": "1158"
    },
    {
        "catalan": "XIV",
        "english": "14th",
        "usageinwikipedia": "1156"
    },
    {
        "catalan": "considerat",
        "english": "considerate",
        "usageinwikipedia": "1155"
    },
    {
        "catalan": "nacionals",
        "english": "national",
        "usageinwikipedia": "1155"
    },
    {
        "catalan": "podria",
        "english": "I could",
        "usageinwikipedia": "1154"
    },
    {
        "catalan": "pel·lícula",
        "english": "movie",
        "usageinwikipedia": "1153"
    },
    {
        "catalan": "etapa",
        "english": "stage",
        "usageinwikipedia": "1152"
    },
    {
        "catalan": "òpera",
        "english": "opera",
        "usageinwikipedia": "1151"
    },
    {
        "catalan": "destacar",
        "english": "highlight",
        "usageinwikipedia": "1150"
    },
    {
        "catalan": "professor",
        "english": "professor",
        "usageinwikipedia": "1149"
    },
    {
        "catalan": "senyor",
        "english": "sir",
        "usageinwikipedia": "1147"
    },
    {
        "catalan": "casar",
        "english": "marry",
        "usageinwikipedia": "1146"
    },
    {
        "catalan": "públic",
        "english": "public",
        "usageinwikipedia": "1141"
    },
    {
        "catalan": "dirigir",
        "english": "manage",
        "usageinwikipedia": "1140"
    },
    {
        "catalan": "plantes",
        "english": "plants",
        "usageinwikipedia": "1140"
    },
    {
        "catalan": "programa",
        "english": "broadcast",
        "usageinwikipedia": "1138"
    },
    {
        "catalan": "fórmula",
        "english": "formula",
        "usageinwikipedia": "1136"
    },
    {
        "catalan": "constitució",
        "english": "constitution",
        "usageinwikipedia": "1131"
    },
    {
        "catalan": "regnat",
        "english": "reign",
        "usageinwikipedia": "1125"
    },
    {
        "catalan": "Àsia",
        "english": "Asia",
        "usageinwikipedia": "1124"
    },
    {
        "catalan": "línies",
        "english": "lines",
        "usageinwikipedia": "1124"
    },
    {
        "catalan": "capacitat",
        "english": "capacity",
        "usageinwikipedia": "1123"
    },
    {
        "catalan": "característiques",
        "english": "characteristics",
        "usageinwikipedia": "1122"
    },
    {
        "catalan": "dur",
        "english": "carry",
        "usageinwikipedia": "1122"
    },
    {
        "catalan": "desenvolupar",
        "english": "to develop",
        "usageinwikipedia": "1121"
    },
    {
        "catalan": "tingué",
        "english": "had",
        "usageinwikipedia": "1120"
    },
    {
        "catalan": "n",
        "english": "n",
        "usageinwikipedia": "1119"
    },
    {
        "catalan": "aire",
        "english": "air",
        "usageinwikipedia": "1117"
    },
    {
        "catalan": "ajuntament",
        "english": "town hall",
        "usageinwikipedia": "1117"
    },
    {
        "catalan": "doncs",
        "english": "so",
        "usageinwikipedia": "1117"
    },
    {
        "catalan": "José",
        "english": "José",
        "usageinwikipedia": "1117"
    },
    {
        "catalan": "comunista",
        "english": "communist",
        "usageinwikipedia": "1115"
    },
    {
        "catalan": "parc",
        "english": "park",
        "usageinwikipedia": "1115"
    },
    {
        "catalan": "aliats",
        "english": "allies",
        "usageinwikipedia": "1114"
    },
    {
        "catalan": "edició",
        "english": "issue",
        "usageinwikipedia": "1112"
    },
    {
        "catalan": "aliança",
        "english": "alliance",
        "usageinwikipedia": "1110"
    }
]